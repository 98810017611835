<script lang="ts" setup>
import lozad from 'lozad';

useHead({
  title: 'Page not found - ZapScale',
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});

defineProps(['error']);

const router = useRouter();
const route = useRoute();

const searchText = ref('');

function onSubmitSearchForm() {
  if (!searchText.value) {
    return;
  }

  router.replace({
    path: '/search-results',
    query: {
      q: searchText.value.trim(),
    },
  });
}

if (process.client) {
  window.addEventListener('keydown', function (event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmitSearchForm();
    }
  });

  watch(
    () => route,
    () => {
      const media = document.querySelectorAll('video, img');
      const observer = lozad(media);
      observer.observe();

      const images = document.getElementsByTagName('img');
      for (let i = 0; i < images.length; i++) {
        let image = images[i];
        if (image.hasAttribute('src')) {
          image.src.replace(/http:/g, 'https:');
        }
        if (image.hasAttribute('srcset')) {
          image.srcset.replace(/http:/g, 'https:');
        }
      }
    },
    {
      immediate: true,
      deep: true,
    },
  );
}
</script>

<template>
  <div class="min-h-screen">
    <Header class="fixed top-0 z-10 w-full" />
    <div
      class="container mx-auto flex grow flex-col justify-center gap-y-10 px-4 py-[4.5rem] md:flex-row md:items-center md:gap-x-10 md:py-[7.5rem]"
    >
      <div class="relative flex h-[354px] items-center justify-center">
        <NuxtImg src="/images/error-page/not-found-bg.svg" width="464" height="303" alt="not-found-background" />
        <NuxtImg src="/images/error-page/not-found.svg" width="381" height="354" class="absolute w-3/5 md:w-2/3" />
      </div>
      <div class="">
        <h1 class="mt-3 text-4xl text-[#3a3a3c] max-md:text-center">Oopsie!</h1>
        <p class="mt-3 text-lg font-normal text-[#3a3a3c] max-md:text-center">
          We couldn’t find the page<br />
          you were looking for
        </p>
        <NuxtLink
          to="/"
          class="mt-3 block h-[41px] w-fit rounded-full bg-[#242424] px-3 py-2 text-white max-md:mx-auto md:px-6"
        >
          <Icon name="solar:arrow-left-linear" />
          Go home
        </NuxtLink>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style scoped>
@media only screen and (min-width: 768px) {
  .search-box {
    width: 40vw;
  }
}

@media only screen and (max-width: 768px) {
  .search-box {
    width: 90vw;
  }
}
</style>
